import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { moduleReducer } from './module.reducer';
import { activityReducer } from './activity.reducer';
import { ltiReducer } from './ltiparams.reducer';
import { assignmentReducer } from './assignment.reducer';
import { questionReducer } from './question.reducer';
import { instructorAssignmentReducer } from './instructor.assignments.reducer';

export const appReducers: ActionReducerMap<AppState, any> = {
  instructorAssignmentsState: instructorAssignmentReducer,
  moduleState: moduleReducer,
  currentActivityState: activityReducer,
  ltiParamsState: ltiReducer,
  assignmentState: assignmentReducer,
  questionAssessmentState: questionReducer,
};
