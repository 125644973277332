import {
  Student,
  StudentAssessmentLastVisitedQuestionStore,
  StudentAssessmentQuestionsChoiceStore,
  StudentAssessmentQuestionsReportStore,
  StudentClassReportStore,
  StudentReportStore,
  PreviewQuestions,
} from 'up';
import {
  Assignment,
  AssignmentPreviewContentUpdate,
  AssignmentsActivity,
  InstructorAssignments,
} from '../../interfaces/assignment.interface';
import {
  ActivityStoreClassReport,
  AssignmentStoreClassReport,
} from '../../interfaces/class-report.interface';
import {
  InstructorAssignmentActions,
  InstructorAssignmentActionsUnion,
} from '../actions/instructor.assignment.action';

import {
  initialInstructorAssignmentsState,
  InstructorAssignmentsState,
} from '../state/instructor.assignments.state';
import { Simulation } from '../../interfaces/preview.interface';

export const instructorAssignmentReducer = (
  state = initialInstructorAssignmentsState,
  action: InstructorAssignmentActionsUnion,
): InstructorAssignmentsState => {
  switch (action.type) {
    case InstructorAssignmentActions.LoadInstructorAssignments: {
      return InstructorAssignmentsReducers.loadAssignments(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.UpdateAssignmentPreviewContent: {
      return InstructorAssignmentsReducers.updateAssignmentPreviewContent(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.UpdateStagedAssignment: {
      return InstructorAssignmentsReducers.updateStagedAssignment(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.AddAssignmentClassReport: {
      return InstructorAssignmentsReducers.addAssignmentClassReport(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.AddStudentsProgress: {
      return InstructorAssignmentsReducers.addStudentsProgress(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.ClearStudentsProgress: {
      return InstructorAssignmentsReducers.clearStudentsProgress(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.AddActivityClassReport: {
      return InstructorAssignmentsReducers.addActivityClassReport(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.AddActivityStudentsProgress: {
      return InstructorAssignmentsReducers.addActivityStudentsProgress(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.ClearActivityStudentsProgress: {
      return InstructorAssignmentsReducers.clearActivityStudentsProgress(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.AddStudentClassReport: {
      return InstructorAssignmentsReducers.addStudentClassReport(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.AddStudentAssessmentQuestionsReport: {
      return InstructorAssignmentsReducers.addStudentAssessmentQuestionsReport(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.UpdateStudentAssessmentQuestionsChoiceOption: {
      return InstructorAssignmentsReducers.updateStudentAssessmentQuestionsChoiceOption(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.UpdateQuestionLastVisitedStatus: {
      return InstructorAssignmentsReducers.updateQuestionLastVisitedStatus(
        state,
        action.payload,
      );
    }

    case InstructorAssignmentActions.ClearQuestionLastVisitedStatus: {
      return InstructorAssignmentsReducers.clearQuestionLastVisitedStatus(
        state,
        action.payload,
      );
    }

    default:
      return state;
  }
};

export class InstructorAssignmentsReducers {
  static loadAssignments(
    state: InstructorAssignmentsState,
    payload: InstructorAssignments,
  ): InstructorAssignmentsState {
    return {
      ...state,
      assignments: payload,
    };
  }

  static updateAssignmentPreviewContent(
    state: InstructorAssignmentsState,
    payload: AssignmentPreviewContentUpdate,
  ): InstructorAssignmentsState {
    const assignmentKey =
      payload.assignmentType === 'staged'
        ? 'stagedAssignments'
        : 'deployedAssignments';

    const updatedAssignments = state.assignments[assignmentKey].map(
      (assignment) =>
        assignment.index === payload.assignmentIndex
          ? {
              ...assignment,
              activities: this.updateActivities(
                assignment.activities,
                payload.activityIndex,
                payload.previewContent,
              ),
            }
          : assignment,
    );

    return {
      ...state,
      assignments: {
        ...state.assignments,
        [assignmentKey]: updatedAssignments,
      },
    };
  }

  private static updateActivities(
    activities: AssignmentsActivity[],
    activityIndex: number,
    previewContent: string | PreviewQuestions[] | Simulation,
  ): AssignmentsActivity[] {
    return activities.map((activity, index) =>
      index === activityIndex ? { ...activity, previewContent } : activity,
    );
  }

  static updateStagedAssignment(
    state: InstructorAssignmentsState,
    payload: Assignment,
  ): InstructorAssignmentsState {
    const updatedStagedAssignment = state.assignments.stagedAssignments.map(
      (assign) =>
        assign.index === payload.index ? { ...assign, ...payload } : assign,
    );

    return {
      ...state,
      assignments: {
        ...state.assignments,
        stagedAssignments: updatedStagedAssignment,
      },
    };
  }

  static addAssignmentClassReport(
    state: InstructorAssignmentsState,
    payload: AssignmentStoreClassReport,
  ): InstructorAssignmentsState {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        return assignment.index === payload.assignmentIndex
          ? {
              ...assignment,
              classProgress: payload.classProgress,
            }
          : assignment;
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static addStudentsProgress(
    state: InstructorAssignmentsState,
    payload: StudentReportStore,
  ): InstructorAssignmentsState {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        if (
          assignment.index === payload.assignmentIndex &&
          assignment.studentsProgress
        ) {
          let _assignment = JSON.parse(JSON.stringify(assignment));
          _assignment.studentsProgress.studentList?.push(
            payload.studentList[0],
          );

          return {
            ...assignment,
            studentsProgress: _assignment.studentsProgress,
          };
        } else {
          return assignment.index === payload.assignmentIndex
            ? {
                ...assignment,
                studentsProgress: payload,
              }
            : assignment;
        }
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static clearStudentsProgress(
    state: InstructorAssignmentsState,
    payload: number,
  ): InstructorAssignmentsState {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        let _assignment = JSON.parse(JSON.stringify(assignment));
        if (assignment.index === payload && assignment.studentsProgress) {
          delete _assignment.studentsProgress;
        }
        return {
          ...assignment,
          studentsProgress: _assignment.studentsProgress,
        };
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static addActivityClassReport(
    state: InstructorAssignmentsState,
    payload: ActivityStoreClassReport,
  ) {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        if (assignment.index === payload.assignmentIndex) {
          const addActivityReport = assignment.activities.map((activity) => {
            if (activity.type === payload.activityType) {
              return {
                ...activity,
                classProgress: payload.activityReport,
              };
            }
            return activity;
          });

          return { ...assignment, activities: addActivityReport };
        }
        return assignment;
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static addActivityStudentsProgress(
    state: InstructorAssignmentsState,
    payload: StudentReportStore,
  ): InstructorAssignmentsState {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        if (assignment.index !== payload.assignmentIndex) return assignment;

        const activityStudentProgress = assignment.activities.map(
          (activity) => {
            if (
              activity.type === payload.activityType &&
              activity.studentsProgress
            ) {
              let _activity = JSON.parse(JSON.stringify(activity));
              _activity.studentsProgress.studentList?.push(
                payload.studentList[0],
              );

              return {
                ...activity,
                studentsProgress: _activity.studentsProgress,
              };
            } else {
              return activity.type === payload.activityType
                ? {
                    ...activity,
                    studentsProgress: payload,
                  }
                : activity;
            }
          },
        );

        return { ...assignment, activities: activityStudentProgress };
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static clearActivityStudentsProgress(
    state: InstructorAssignmentsState,
    payload: {
      assignmentIndex: number;
      activityIndex: number;
      activityType: string;
    },
  ): InstructorAssignmentsState {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        if (assignment.index !== payload.assignmentIndex) return assignment;

        const updatedActivities = assignment.activities.map((activity) =>
          activity.type === payload.activityType
            ? { ...activity, studentsProgress: null }
            : activity,
        );

        return { ...assignment, activities: updatedActivities };
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static addStudentClassReport(
    state: InstructorAssignmentsState,
    payload: StudentClassReportStore,
  ): InstructorAssignmentsState {
    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map((assignment) => {
        if (
          assignment.index === payload.assignmentIndex &&
          assignment.studentsProgress
        ) {
          let _assignment = JSON.parse(JSON.stringify(assignment));
          _assignment.studentsProgress?.studentList[
            payload.pageIndex
          ]?.students?.map((student: Student) => {
            if (student.userxid === payload.userXid) {
              student.classReport = payload.classReport;
            }
          });
          return {
            ...assignment,
            studentsProgress: _assignment.studentsProgress,
          };
        } else {
          return assignment;
        }
      });

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static addStudentAssessmentQuestionsReport(
    state: InstructorAssignmentsState,
    payload: StudentAssessmentQuestionsReportStore,
  ): InstructorAssignmentsState {
    const { assignmentIndex, userxid, activityType, questions, eaid } = payload;

    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map(
        (assignment, _assignmentIndex) => {
          if (assignmentIndex !== assignment.index) return assignment;

          const { studentsAssessmentQuestionsReport = [] } = assignment;

          const updatedReport = studentsAssessmentQuestionsReport.map(
            (student: any) => {
              if (student[userxid]) {
                return {
                  ...student,
                  [userxid]: {
                    ...student[userxid],
                    eaid: eaid,
                    [activityType]: questions,
                  },
                };
              }

              return {
                ...student,
                [userxid]: {
                  eaid: eaid,
                  [activityType]: questions,
                },
              };
            },
          );

          return {
            ...assignment,
            studentsAssessmentQuestionsReport:
              updatedReport.length > 0
                ? updatedReport
                : [
                    {
                      [userxid]: {
                        eaid: eaid,
                        [activityType]: questions,
                      },
                    },
                  ],
          };
        },
      );

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static updateStudentAssessmentQuestionsChoiceOption(
    state: InstructorAssignmentsState,
    payload: StudentAssessmentQuestionsChoiceStore,
  ): InstructorAssignmentsState {
    const {
      assignmentIndex,
      userxid,
      activityType,
      questionId,
      questionContent,
    } = payload;

    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map(
        (assignment, _assignmentIndex) => {
          if (assignment.index !== assignmentIndex) return assignment;

          const updatedReports =
            assignment.studentsAssessmentQuestionsReport.map((student: any) => {
              if (!(userxid in student)) return student;

              const studentActivity = student[userxid][activityType];
              if (!studentActivity) return student;

              const updatedQuestions = studentActivity.map((question: any) =>
                question.qid === questionId
                  ? { ...question, questionContent }
                  : question,
              );

              return {
                ...student,
                [userxid]: {
                  ...student[userxid],
                  [activityType]: updatedQuestions,
                },
              };
            });

          return {
            ...assignment,
            studentsAssessmentQuestionsReport: updatedReports,
          };
        },
      );

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static updateQuestionLastVisitedStatus(
    state: InstructorAssignmentsState,
    payload: StudentAssessmentLastVisitedQuestionStore,
  ): InstructorAssignmentsState {
    const {
      assignmentIndex,
      userxid,
      activityType,
      questionId,
      isLastVisited,
    } = payload;

    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map(
        (assignment, _assignmentIndex) => {
          if (assignment.index !== assignmentIndex) return assignment;

          const updatedReports =
            assignment.studentsAssessmentQuestionsReport.map((student: any) => {
              if (!(userxid in student)) return student;

              const studentActivity = student[userxid][activityType];
              if (!studentActivity) return student;

              const updatedQuestions = studentActivity.map((question: any) =>
                question.qid === questionId
                  ? { ...question, isLastVisited: isLastVisited }
                  : question,
              );

              return {
                ...student,
                [userxid]: {
                  ...student[userxid],
                  [activityType]: updatedQuestions,
                },
              };
            });

          return {
            ...assignment,
            studentsAssessmentQuestionsReport: updatedReports,
          };
        },
      );

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }

  static clearQuestionLastVisitedStatus(
    state: InstructorAssignmentsState,
    payload: StudentAssessmentLastVisitedQuestionStore,
  ): InstructorAssignmentsState {
    const { assignmentIndex, userxid, activityType } = payload;

    const updatedDeployedAssignments =
      state.assignments.deployedAssignments.map(
        (assignment, _assignmentIndex) => {
          if (assignment.index !== assignmentIndex) return assignment;

          const updatedReports =
            assignment.studentsAssessmentQuestionsReport.map((student: any) => {
              if (!(userxid in student)) return student;

              const studentActivity = student[userxid][activityType];
              if (!studentActivity) return student;

              const updatedQuestions = studentActivity.map((question: any) =>
                question.isLastVisited === true
                  ? { ...question, isLastVisited: false }
                  : question,
              );

              return {
                ...student,
                [userxid]: {
                  ...student[userxid],
                  [activityType]: updatedQuestions,
                },
              };
            });

          return {
            ...assignment,
            studentsAssessmentQuestionsReport: updatedReports,
          };
        },
      );

    return {
      ...state,
      assignments: {
        ...state.assignments,
        deployedAssignments: updatedDeployedAssignments,
      },
    };
  }
}
