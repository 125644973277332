import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/vl/src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { UPUtilityService } from 'up';

@Component({
  selector: 'vl-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  apiErrorConfig = {
    heading: 'GENERIC.ERROR.HEADING',
    message: 'GENERIC.ERROR.MESSAGE',
    buttonText: 'GENERIC.ERROR.EXIT_BUTTON',
    moreDetails: '',
  };
  constructor(
    private upUtilityService: UPUtilityService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.translate
      .get(['GENERIC.PAGE_TITLE', 'GENERIC.ERROR.PAGE_TITLE'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (pageTitle) => {
          const errorPageTitle =
            pageTitle['GENERIC.PAGE_TITLE'] +
            pageTitle['GENERIC.ERROR.PAGE_TITLE'];
          this.upUtilityService.setPageTitle(errorPageTitle);
        },
      });
  }

  onExitClick() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/vl`);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
