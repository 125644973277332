import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestionAssessmentState } from '../state/question.state';

const getQuestionAssessmentState =
  createFeatureSelector<QuestionAssessmentState>('questionAssessmentState');

export const getQuestionsAssessment = createSelector(
  getQuestionAssessmentState,
  (state: QuestionAssessmentState) => state.questionAssessmentInfo,
);
