import { createFeatureSelector, createSelector } from '@ngrx/store';
import { assignmentState } from '../state/assignment.state';

const getAssignmentState =
  createFeatureSelector<assignmentState>('assignmentState');

export const getAssignmentActivityDetails = createSelector(
  getAssignmentState,
  (state: assignmentState) => state.assignmentActivity,
);
