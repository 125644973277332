import { Action } from '@ngrx/store';
import {
  Assignment,
  AssignmentPreviewContentUpdate,
  InstructorAssignments,
} from '../../interfaces/assignment.interface';
import { AssignmentStoreClassReport } from '../../interfaces/class-report.interface';
import { ActivityStoreClassReport } from '../../interfaces/class-report.interface';
import {
  StudentAssessmentLastVisitedQuestionStore,
  StudentAssessmentQuestionsChoiceStore,
  StudentAssessmentQuestionsReportStore,
  StudentClassReportStore,
  StudentReportStore,
} from 'up';
export enum InstructorAssignmentActions {
  LoadInstructorAssignments = '[Assignments] Load Instructor Assignments',
  UpdateAssignmentPreviewContent = '[Assignment] Update Activity Preview Content',
  UpdateStagedAssignment = '[Assignments] Update Staged Assignment',
  AddAssignmentClassReport = '[Assignment] Add Assignment Class Report',
  AddStudentsProgress = '[Assignment] Add Students Progress',
  ClearStudentsProgress = '[Assignment] Clear Students Progress',
  AddActivityClassReport = '[Assignment] Add Activity Class Report',
  AddActivityStudentsProgress = '[Assignment] Add Activity Students Progress',
  ClearActivityStudentsProgress = '[Assignment] Clear Activity Student Progress',
  AddStudentClassReport = '[Assignment] Add Student Class Report',
  AddStudentAssessmentQuestionsReport = '[Assignment] Add Student Assessment Questions Report',
  UpdateStudentAssessmentQuestionsChoiceOption = '[Assignment] Update Student Assessment Questions Choice Option',
  UpdateQuestionLastVisitedStatus = '[Assignment] Update Question Last Visited Status',
  ClearQuestionLastVisitedStatus = '[Assignment] Clear Question Last Visited Status',
}
export class LoadInstructorAssignments implements Action {
  public readonly type = InstructorAssignmentActions.LoadInstructorAssignments;
  constructor(public payload: InstructorAssignments) {}
}
export class UpdateAssignmentPreviewContent implements Action {
  public readonly type =
    InstructorAssignmentActions.UpdateAssignmentPreviewContent;
  constructor(public payload: AssignmentPreviewContentUpdate) {}
}
export class UpdateStagedAssignment implements Action {
  public readonly type = InstructorAssignmentActions.UpdateStagedAssignment;
  constructor(public payload: Assignment) {}
}
export class AddAssignmentClassReport implements Action {
  public readonly type = InstructorAssignmentActions.AddAssignmentClassReport;
  constructor(public payload: AssignmentStoreClassReport) {}
}
export class AddStudentsProgress implements Action {
  public readonly type = InstructorAssignmentActions.AddStudentsProgress;
  constructor(public payload: StudentReportStore) {}
}
export class ClearStudentsProgress implements Action {
  public readonly type = InstructorAssignmentActions.ClearStudentsProgress;
  constructor(public payload: number) {}
}
export class AddActivityClassReport implements Action {
  public readonly type = InstructorAssignmentActions.AddActivityClassReport;
  constructor(public payload: ActivityStoreClassReport) {}
}
export class ClearActivityStudentsProgress implements Action {
  public readonly type =
    InstructorAssignmentActions.ClearActivityStudentsProgress;
  constructor(
    public payload: {
      assignmentIndex: number;
      activityIndex: number;
      activityType: string;
    },
  ) {}
}
export class AddStudentClassReport implements Action {
  public readonly type = InstructorAssignmentActions.AddStudentClassReport;
  constructor(public payload: StudentClassReportStore) {}
}

export class AddActivityStudentsProgress implements Action {
  public readonly type =
    InstructorAssignmentActions.AddActivityStudentsProgress;
  constructor(public payload: StudentReportStore) {}
}
export class AddStudentAssessmentQuestionsReport implements Action {
  public readonly type =
    InstructorAssignmentActions.AddStudentAssessmentQuestionsReport;
  constructor(public payload: StudentAssessmentQuestionsReportStore) {}
}
export class UpdateStudentAssessmentQuestionsChoiceOption implements Action {
  public readonly type =
    InstructorAssignmentActions.UpdateStudentAssessmentQuestionsChoiceOption;
  constructor(public payload: StudentAssessmentQuestionsChoiceStore) {}
}
export class UpdateQuestionLastVisitedStatus implements Action {
  public readonly type =
    InstructorAssignmentActions.UpdateQuestionLastVisitedStatus;
  constructor(public payload: StudentAssessmentLastVisitedQuestionStore) {}
}
export class ClearQuestionLastVisitedStatus implements Action {
  public readonly type =
    InstructorAssignmentActions.ClearQuestionLastVisitedStatus;
  constructor(public payload: StudentAssessmentLastVisitedQuestionStore) {}
}

export type InstructorAssignmentActionsUnion =
  | LoadInstructorAssignments
  | UpdateAssignmentPreviewContent
  | UpdateStagedAssignment
  | AddAssignmentClassReport
  | AddStudentsProgress
  | ClearStudentsProgress
  | AddActivityClassReport
  | AddStudentClassReport
  | AddActivityStudentsProgress
  | ClearActivityStudentsProgress
  | AddStudentAssessmentQuestionsReport
  | UpdateStudentAssessmentQuestionsChoiceOption
  | UpdateQuestionLastVisitedStatus
  | ClearQuestionLastVisitedStatus;
