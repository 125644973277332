import {
  ActivityActions,
  ActivityActionsUnion,
} from '../actions/activity.action';
import {
  CurrentActivityPreviewState,
  initialActivityPreviewState,
} from '../state/activity.state';

export const activityReducer = (
  state = initialActivityPreviewState,
  action: ActivityActionsUnion,
): CurrentActivityPreviewState => {
  switch (action.type) {
    case ActivityActions.LoadActivity: {
      return {
        ...state,
        activity: action.payload,
      };
    }
    default:
      return state;
  }
};
