import {
  AddPreviewFilter,
  Module,
  PreviewContentUpdate,
  UpdatePreviewFilter,
} from '../../interfaces/module.interface';
import { Action } from '@ngrx/store';

export enum ModulesActions {
  LoadModules = '[Modules] Load Modules',
  UpdateActivityStatus = '[Modules] Update Activity Selection Status',
  UpdatePreviewContent = '[Modules] Update Activity Preview Content',
  AddPreviewFilters = '[Modules] Add Preview Filter',
  UpdatePreviewFilters = '[Modules] Update Preview Filter',
  UpdateLastVisitedStatus = '[Modules] Update Last Visited Status',
}

export class LoadModules implements Action {
  public readonly type = ModulesActions.LoadModules;
  constructor(public payload: Module[]) {}
}

export class UpdatePreviewContent implements Action {
  public readonly type = ModulesActions.UpdatePreviewContent;
  constructor(public payload: PreviewContentUpdate) {}
}
export class AddPreviewFilters implements Action {
  public readonly type = ModulesActions.AddPreviewFilters;
  constructor(public payload: AddPreviewFilter) {}
}
export class UpdatePreviewFilters implements Action {
  public readonly type = ModulesActions.UpdatePreviewFilters;
  constructor(public payload: UpdatePreviewFilter) {}
}

export class UpdateLastVisitedStatus implements Action {
  public readonly type = ModulesActions.UpdateLastVisitedStatus;
  constructor(public payload: number) {}
}

export type ModulesActionsUnion =
  | LoadModules
  | UpdatePreviewContent
  | AddPreviewFilters
  | UpdatePreviewFilters
  | UpdateLastVisitedStatus;
