import { Action } from '@ngrx/store';
import {
  AssignmentSubmitResponse,
  AssignmentDetails,
} from '../../interfaces/assignment.interface';

export enum AssignmentActions {
  LoadAssignment = '[Assignment] Load Assignment',
  UpdateActivityStatus = '[Assignment] Update Activity Status',
  UpdateAssignment = '[Assignment] Update Assignment',
}

export class LoadAssignment implements Action {
  public readonly type = AssignmentActions.LoadAssignment;
  constructor(public payload: AssignmentDetails) {}
}
export class UpdateActivityStatus implements Action {
  public readonly type = AssignmentActions.UpdateActivityStatus;
  constructor(
    public payload: { activityType: string; activityStatus: string },
  ) {}
}

export class UpdateAssignment implements Action {
  public readonly type = AssignmentActions.UpdateAssignment;
  constructor(public payload: AssignmentSubmitResponse) {}
}

export type AssignmentActionsUnion =
  | LoadAssignment
  | UpdateActivityStatus
  | UpdateAssignment;
