import {
  AssignmentActions,
  AssignmentActionsUnion,
} from '../actions/assignment.action';
import {
  assignmentState,
  initialAssignmentState,
} from '../state/assignment.state';

export const assignmentReducer = (
  state = initialAssignmentState,
  action: AssignmentActionsUnion,
): assignmentState => {
  switch (action.type) {
    case AssignmentActions.LoadAssignment: {
      return {
        ...state,
        assignmentActivity: action.payload,
      };
    }
    case AssignmentActions.UpdateActivityStatus: {
      const updatedActivites = state.assignmentActivity.activities.map(
        (activity) => {
          if (activity.type === action.payload.activityType) {
            return {
              ...activity,
              status: action.payload.activityStatus,
            };
          }
          return activity;
        },
      );

      return {
        ...state,
        assignmentActivity: {
          ...state.assignmentActivity,
          activities: updatedActivites,
        },
      };
    }
    case AssignmentActions.UpdateAssignment: {
      return {
        ...state,
        assignmentActivity: {
          ...state.assignmentActivity,
          score: action.payload.score,
          isSubmitted: action.payload.isSubmitted,
        },
      };
    }
    default:
      return state;
  }
};
