import { Assignment } from '../../interfaces/assignment.interface';

export interface InstructorAssignmentsState {
  assignments: {
    stagedAssignments: Assignment[];
    deployedAssignments: Assignment[];
  };
}

export const initialInstructorAssignmentsState: InstructorAssignmentsState = {
  assignments: {
    stagedAssignments: [
      {
        index: -1,
        title: '',
        activities: [
          {
            type: 'preLab',
            title: '',
            previewContent: '',
          },
          {
            type: 'simulation',
            title: '',
            index: -1,
            previewContent: '',
          },
          {
            type: 'postLab',
            title: '',
            previewContent: '',
          },
        ],
        classProgress: null,
        studentsProgress: null,
      },
    ],
    deployedAssignments: [
      {
        title: '',
        index: -1,
        activities: [
          {
            type: 'preLab',
            title: '',
            previewContent: '',
          },
          {
            type: 'simulation',
            title: '',
            index: -1,
            previewContent: '',
          },
          {
            type: 'postLab',
            title: '',
            previewContent: '',
          },
        ],
        classProgress: null,
        studentsProgress: null,
      },
    ],
  },
};
