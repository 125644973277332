import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UPUtilityService, ltiParams } from 'up';
import { Product } from 'up/lib/interfaces/product.interface';
import { LtiParamsState } from '../../../shared/store/state/ltiparams.state';
import { Store } from '@ngrx/store';
import { getLtiParams } from '../../../shared/store/selectors/ltiparams.selector';
@Component({
  selector: 'vl-product-launch',
  templateUrl: './product-launch.component.html',
  styleUrls: ['./product-launch.component.scss'],
})
export class ProductLaunchComponent implements OnInit, OnDestroy {
  product = signal<Product>(null!);
  private destroy$ = new Subject<boolean>();
  isProductLoaded = signal(false);
  THUMBNAIL = 'https://covers.mhedu.com/Jpeg_wide-75/';
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  productWarningParam = {
    state: {
      platform: 'Virtual Labs',
    },
  };
  constructor(
    private store: Store<LtiParamsState>,
    private router: Router,
    private upUtilityService: UPUtilityService,
  ) {}

  ngOnInit(): void {
    this.launchVL();
  }

  async launchVL() {
    try {
      const newTab = await this.upUtilityService.launchAppInNewTab('VL');

      if (newTab) {
        newTab.location.href = location.origin + '/product/assignments';
        this.router.navigate(['/product/warning'], this.productWarningParam);
      } else {
        this.getLtiParams();
      }
    } catch (error) {
      console.error('Error opening app:', error);
    }
  }

  loadPairedProduct() {
    this.product = signal({
      author: this.ltiParams?.productAuthor!,
      edition: this.ltiParams?.productEdition!,
      isbn: this.ltiParams?.isbn!,
      title: this.ltiParams?.productTitle!,
    });
    this.isProductLoaded.set(true);
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.loadPairedProduct();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
