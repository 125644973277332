import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UPBsModalConfig,
  UPBsModalEvent,
  ModalSubmissionType,
  UPUtilityService,
} from 'up';

import { environment } from 'projects/vl/src/environments/environment';
@Component({
  selector: 'vl-interceptor-error',
  templateUrl: './interceptor-error.component.html',
  styleUrls: ['./interceptor-error.component.scss'],
})
export class InterceptorErrorComponent implements OnInit {
  bsModalConfig: UPBsModalConfig = {
    title: '',
    submitType: ModalSubmissionType.CLOSE_MODAL,
    submitBtnText: 'GENERIC.MODAL.OK_BUTTON',
    closeBtnText: '',
    hideCloseBtn: true,
    hideFooter: false,
    hideCloseIcon: true,
    featureType: '',
  };
  @Input() modalTitle!: string;
  @Input() modalBody!: string;

  constructor(
    private modalService: NgbModal,
    private upUtilityService: UPUtilityService,
  ) {}

  ngOnInit(): void {
    this.bsModalConfig.title = this.modalTitle;
    this.bsModalConfig.featureType = 'authentication-error';
  }

  handleModalEvent(event: UPBsModalEvent) {
    switch (event.type) {
      case ModalSubmissionType.CLOSE_MODAL:
        this.onExit();
        break;
    }
    this.modalService.dismissAll();
  }

  onExit() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/vl`);
  }
}
